<template>
  <div class="paginator">
    <div class="paginator-content">
      <div
        v-if="!showAll"
        class="button noselect previous"
        :class="{'disabled': currentPage === 1 || showAll}"
        @click="previousPage()"
      >
        <font-awesome-icon
          class="chevron-icon"
          icon="chevron-left"
        />
      </div>
      <div
        v-if="allowShowAll && !showAll"
        class="divider"
      />
      <div
        v-if="allowShowAll"
        class="button noselect show_all"
        @click="showAllPages"
      >
        <h6>
          {{ showAll ? $t('Components.Pagination.hide') : $t('Components.Pagination.show_all') }}
        </h6>
      </div>
      <div
        v-if="!showAll"
        class="divider"
      />
      <div
        v-if="!showAll"
        class="button noselect next"
        :class="{'disabled': currentPage === lastPage || showAll}"
        @click="nextPage()"
      >
        <font-awesome-icon
          class="chevron-icon"
          icon="chevron-right"
        />
      </div>
    </div>
    <h6 v-if="!showAll">
      {{ currentPage }} / {{ maxPages }}
    </h6>
    <h6 v-else>
      {{ $t('Components.Pagination.showing_all') }}
    </h6>
  </div>
</template>

<script>

export default {
  props: {
    value: {
      name: 'currentPage',
      type: Number,
      required: true,
    },
    allowShowAll: {
      type: Boolean,
      required: false,
      default: true,
    },
    maxPages: {
      type: Number,
      required: true,
    },
    showAll: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  computed: {
    currentPage() {
      return this.value;
    },
    lastPage() {
      return this.maxPages;
    },
  },
  methods: {
    onClick(number) {
      this.$emit('input', number);
    },
    previousPage() {
      if (this.value <= 1) return;
      const newVal = this.value - 1;
      this.$emit('input', newVal);
      this.$emit('prev');
    },
    nextPage() {
      if (this.value >= this.lastPage) return;
      const newVal = this.value + 1;
      this.$emit('input', newVal);
      this.$emit('next');
    },
    showAllPages() {
      this.$emit('show-all', !this.showAll);
    },
  },
};

</script>

<style lang="scss" scoped>

  $height: 3rem;

  .paginator{
    display: flex;
    flex-direction: column;
    margin: auto;

    .paginator-content{
      display: flex;
      margin: auto;
    }

    h6{
      display: flex;
      margin: 1rem auto;
    }
  }

  .paginator-content {
    border: 2px solid var(--primary-color);
    justify-content: space-between;
    padding: 0;
    height: $height;
    border-radius: 2rem;
    width: fit-content;

    h6 {
      color: var(--primary-color);

    }

    .disabled {
      * {
        color: gray;
        opacity: 0.5;
      }
    }

    .chevron-icon {
      color: var(--primary-color);
    }

    *:not(.chevron-icon) {
      margin: auto 1rem auto 1rem;
    }

    .divider {
      height: calc(#{$height} / 2);
      width: 2px;
      background-color: var(--primary-color);
    }

    .button {
      margin: auto 0;
      padding: 0.5rem 2rem;
      cursor: default;

      &:hover:not(.disabled) {
        cursor: pointer;

        * {
          color: var(--color-resource-free);
        }
      }
    }
  }

</style>
